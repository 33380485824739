<template>
  <div class="info">
    <top-bar :title="'问题详情'" :left="true"></top-bar>
    <van-tabs>
      <van-tab title="问题详情">
        <van-cell-group>
          <!--          <van-cell>-->
          <!--            <template #title>-->
          <!--              <div class="blue-box"></div>-->
          <!--              <span class="custom-title">问题信息</span>-->
          <!--            </template>-->
          <!--          </van-cell>-->
          <van-cell title="提问人姓名" :value="detailData.name"/>
          <van-cell title="电话" :value="detailData.phoneNumber"/>
          <van-cell title="所在小区" :value="detailData.subArea"/>
          <van-cell title="位置" :value="detailData.houseFullName"/>
          <van-cell title="问题类别" :value="detailData.typeStr"/>
          <van-cell title="问题描述" class="cont">
            <van-field
                v-model="detailData.content"
                disabled
                autosize
                type="textarea"
                placeholder="这是一条问题描述"
                style="text-align: right;  padding:0"
            />
          </van-cell>
        </van-cell-group>
        <van-uploader v-model="fileList" :multiple="false" :deletable="false" :show-upload="false"
                      style="padding:20px"/>
      </van-tab>
      <van-tab title="处理详情" v-if="problemData.status == 0 || problemData.status == 30">
        <!--        <van-cell>-->
        <!--          <template #title>-->
        <!--            <div class="blue-box"></div>-->
        <!--            <span class="custom-title">处理详情</span>-->
        <!--          </template>-->
        <!--        </van-cell>-->
        <van-cell title="处理人" :value="detailData.solveUserName"/>
        <van-cell title="联系电话" :value="detailData.solveUserPhoneNumber"/>
        <van-cell title="处理时间" :value="detailData.solveTime"/>
        <van-cell title="处理结果" class="cont">
          <van-field v-model="detailData.solveDes" autosize disabled
                     type="textarea"
                     placeholder="这是处理结果"
                     style="text-align: right;  padding:0"/>
        </van-cell>
        <div class="uploadBox">
          <van-uploader v-model="solveFileList" :multiple="false" :deletable="false" :show-upload="false"
                        style="padding:20px"/>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import {mapState, mapMutations} from 'vuex'

export default {
  data() {
    return {
      value: '',
      detailData: {},
      fileList: [],
      solveFileList: []
    }
  },
  computed: {...mapState(['problemData'])},
  components: {
    topBar
  },
  methods: {
    getDetail() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.problemData.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.entityVO.problemFiles && data.entityVO.problemFiles.length != 0) {
            data.entityVO.problemFiles.forEach(item => {
              console.log(item);
              let u;
              if (item.type == 1) {
                u = getImageStream(item.filePath)

                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  url: u,
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.fileList.push(url);
              }
            })
          }
          if (data.entityVO.problemSolveFiles && data.entityVO.problemSolveFiles.length != 0) {
            data.entityVO.problemSolveFiles.forEach(item => {
              console.log(item);
              let u;
              if (item.type == 1) {
                u = getImageStream(item.filePath)

                let url = {
                  uid: this.solveFileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  url: u,
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.solveFileList.push(url);
              }
            })
          }
          this.detailData = data.entityVO
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    }
  },
  created() {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.getDetail()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style>
a {
  color: rgb(4, 50, 255);
}

.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}

.van-field__control {
  text-align: right;
}
</style>
<style lang="scss" scoped>
.cont {
  .van-cell__value {
    width: 500px;
    flex: 2;
  }
}

.van-uploader__upload {
  display: none !important;

  .van-uploader__input {
    display: none !important;
  }
}

.van-divider {
  border-color: #000;
}

.info {
  padding: 0;
  margin-top: 0;
  margin-bottom: 100px;
  background-color: #FFFFFF;
}
</style>
